<template>
  <div :id="componentId" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="md" :title="labelTitle" 
      @hidden="$emit('update:show', false)" :modal-class="[componentId]"
      content-class="shadow" no-close-on-backdrop>
    
      <b-alert variant="danger" dismissible v-model="errorShow" @dismissed="dismissAlert">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }} 
      </b-alert>
    
      <div class="container pl-0">
        <b-row>
          <b-col cols="12" class="pr-0">
            <b-form-group :label="$t('task_predecessor.field.name')" label-for="predecessor-name">
              <b-input-group>
                <Treeselect instance-id="predecessor-name" :default-expand-level="Infinity" 
                  flat
                  v-model="editPredecessor.uuId" 
                  :multiple="false" 
                  :options="optionTask"
                  :data-vv-as="$t('task_predecessor.field.name')"
                  data-vv-name="editPredecessor.uuId"
                  data-vv-delay="500"
                  :disabled="readOnly"/>
              </b-input-group>
              <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showNameError }">
                <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('editPredecessor.name') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['name'] != null">
            <b-col v-for="(field, index) in customFieldMap['name']" :key="'name'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="editPredecessor[field.name]" :componentId="componentId" :field="field" :disabled="readOnly"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <template v-if="customFieldMap['default'] != null">
            <b-col v-for="(field, index) in customFieldMap['default']" :key="index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="editPredecessor[field.name]" :componentId="componentId" :field="field" :disabled="readOnly"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <b-col cols="12" class="pr-0">
            <b-form-group :label="$t('task_predecessor.field.type')" label-for="predecessor-type">
              <b-form-select id="predecessor-type" 
                v-model="editPredecessor.type" 
                :options="optionTaskLinkType"
                text-field="label" 
                value-field="key"
                :data-vv-as="$t('task_predecessor.field.type')"
                data-vv-name="editPredecessor.type"
                data-vv-delay="500"
                :disabled="readOnly"
              />
              <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showTypeError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('editPredecessor.type') }}
                </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['type'] != null">
            <b-col v-for="(field, index) in customFieldMap['type']" :key="'type'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="editPredecessor[field.name]" :componentId="componentId" :field="field" :disabled="readOnly"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <b-col cols="12" class="pr-0">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label for="predecessor-tag" class="mr-2">{{$t('task_predecessor.field.lag')}}</label>
                <font-awesome-icon id="predecessor-lag" :icon="['far', 'circle-question']" class="mb-2" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                <b-popover target="predecessor-lag" triggers="hover" placement="top">
                  {{ $t('task_predecessor.field.lag_hint') }}
                </b-popover>
              </div>
              <b-input-group>
                <b-input-group-prepend>
                  <b-button id="LAG_SUBTRACT" @click.prevent="lagAddMinus(-1)">
                    <font-awesome-icon :icon="['far', 'minus']"/>
                    <b-popover
                      target="LAG_SUBTRACT"
                      placement="top"
                      triggers="hover"
                      :content="$t('task.button.lag_subtract')">
                    </b-popover>
                  </b-button>
                </b-input-group-prepend>
                <b-form-input id="predecessor-tag" type="text" @blur="lagFormat"
                  v-model="editPredecessor.lag"
                  :data-vv-as="$t('task_predecessor.field.lag')"
                  data-vv-name="editPredecessor.lag"
                  data-vv-delay="500"
                  :readonly="readOnly"
                />
                <b-input-group-append>
                  <b-button id="LAG_ADD" @click.prevent="lagAddMinus(1)">
                    <font-awesome-icon :icon="['far', 'plus']"/>
                    <b-popover
                      target="LAG_ADD"
                      placement="top"
                      triggers="hover"
                      :content="$t('task.button.lag_add')">
                    </b-popover>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showLagError }">
                <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('editPredecessor.lag') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['lag'] != null">
            <b-col v-for="(field, index) in customFieldMap['lag']" :key="'lag'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="editPredecessor[field.name]" :componentId="componentId" :field="field" :disabled="readOnly"></CustomField>
              </b-form-group>
            </b-col>
          </template>
        </b-row>
      </div>
      
      
      <template v-slot:modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button v-if="!readOnly" size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button v-if="!readOnly" size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
        <b-button v-else size="sm" variant="secondary" @click="cancel()">{{ $t('button.close') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { taskService, templateTaskService } from '@/services';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { getCustomFieldInfo, customFieldValidate } from '@/helpers/custom-fields';
import { getAppendAfterObjectWithTopDownRelationship } from '@/components/modal/script/field';

export default {
  name: 'TaskPredecessorModal',
  components: {
    Treeselect: () => import('@riophae/vue-treeselect'),
    CustomField: () => import('@/components/CustomField.vue'),
  },
  props: {
    predecessor:  { type: Object, default: null },
    title:        { type: String, default: null },
    show:         { type: Boolean, required: true },
    readOnly:     { type: Boolean, default: false },
    list:         { type: Array, default: () => []},
    projectId:    { type: String, default: null },
    taskId:       { type: String, required: true },
    taskName:     { type: String, default: null },
    isTemplate:   { type: Boolean, default: false }
  },
  data() {
    return {
      modalShow: false,
      alertMsg: null,
      editPredecessor: {
        uuId: null,
        name: null,
        type: null,
        lag: 0
      },
      optionTaskLinkType: [],
      optionTask: [],
      customFields: [],
      customFieldMap: {}
    }
  },
  created() {
    this.fieldValidateUtil = fieldValidateUtil;
    this.optionTaskFlat = [];
    this.populateTaskLinkType();
    this.populateTaskTree();
  },
  mounted() {
    this.modalShow = this.show;
    this.prepareData();
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
    this.optionTaskFlat = null;
  },
  watch: {
    show(newValue) {
      if (newValue != this.modalShow) {
        this.modalShow = newValue;
        this.alertMsg = null;
        this.errors.clear();
        this.populateTaskTree();
        this.prepareData();
      }
    }
  },
  computed: {
    componentId() {
      return `TASK_PREDECESSOR_${this.editPredecessor.uuId}`;
    },
    errorShow() {
      return this.alertMsg != null;
    },
    showNameError() {
      return fieldValidateUtil.hasError(this.errors, 'editPredecessor.name');
    },
    showTypeError() {
      return fieldValidateUtil.hasError(this.errors, 'editPredecessor.type');
    },
    showLagError() {
      return fieldValidateUtil.hasError(this.errors, 'editPredecessor.lag');
    },
    labelTitle() {
      return this.title? this.title : this.$t('task_predecessor.title_edit');
    }
  },
  methods: {
    async prepareData() {
      await getCustomFieldInfo(this, 'PLAN_LINK');
      if (this.customFields.length == 0) {
        this.customFieldMap = {};
      } else {
        this.customFieldMap = getAppendAfterObjectWithTopDownRelationship(this.customFields);
      }

      // clone the predecessor data for editing
      const keys = Object.keys(this.editPredecessor);
      keys.forEach(i => {
        this.editPredecessor[i] = this.predecessor[i];
      });

      for (const field of this.customFields) {
        if (typeof this.predecessor[field.name] !== 'undefined') {
          this.editPredecessor[field.name] = this.predecessor[field.name];
        }
      }

      if(Object.prototype.hasOwnProperty.call(this.predecessor, 'isNew')) {
        this.editPredecessor.isNew = this.predecessor.isNew;
      } else {
        this.editPredecessor.isNew = false;
      }
    },
    lagFormat() {
      if(isNaN(parseInt(this.editPredecessor.lag))) {
        this.editPredecessor.lag = 0;
      }
      const value = parseInt(this.editPredecessor.lag);
      if(value < 0) {
        this.editPredecessor.lag = 0;
      } else {
        this.editPredecessor.lag = value;
      }
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    ok() {
      // const customFields = this.customFieldsFiltered;
      for (const field of this.customFields) {
        if (!customFieldValidate(field, this.editPredecessor[field.name])) {
          field.showError = true;
          return;  
        }
      }

      this.errors.clear();
      this.alertMsg = null;
      if(!this.editPredecessor.uuId) {
        this.errors.add({
          field: 'editPredecessor.name',
          msg: this.$t('validation.messages.required', [this.$t('task_predecessor.field.name')])
        });
      }
      if(!this.editPredecessor.type) {
        this.errors.add({
          field: 'editPredecessor.type',
          msg: this.$t('validation.messages.required', [this.$t('task_predecessor.field.type')])
        });
      }
      if(this.editPredecessor.lag == null) {
        this.errors.add({
          field: 'editPredecessor.lag',
          msg: this.$t('validation.messages.required', [this.$t('task_predecessor.field.lag')])
        });
      }

      if(this.errors.count() < 1 && (this.editPredecessor.isNew || this.editPredecessor.id !== this.predecessor.id)) {
        const p = this.editPredecessor;
        const matched = this.list.filter(i => i.uuId === p.uuId).length > 0;
        if(matched) {
          this.alertMsg = this.$t('task_predecessor.error.identical_exist');
          this.scrollToTop();
        }
      }

      this.$validator.validate().then(valid => {
        if (valid && this.errors.count() < 1) {
          const cloned = cloneDeep(this.editPredecessor);
          cloned.name = this.optionTaskFlat.find(i => i.id === this.editPredecessor.uuId).label;
          let msg = this.$t('task_predecessor.grid.update');
          if(Object.prototype.hasOwnProperty.call(cloned, 'isNew') && cloned.isNew) {
            msg = this.$t('task_predecessor.grid.add');
          }
          
          this.$validator.reset();
          this.$emit('success', { msg, predecessor: cloned });
          this.$emit('update:show', false);
        } else {
          this.alertMsg = this.$t('error.attention_required');
          this.scrollToTop();
        }
      });
    },
    lagAddMinus(delta) {
      if(isNaN(this.editPredecessor.lag)) {
        this.editPredecessor.lag = 0;
      }
      
      if (parseInt(this.editPredecessor.lag) === 0 && delta === -1) {
        return;
      }
      const value = parseInt(this.editPredecessor.lag);
      this.editPredecessor.lag = value + delta;
    },
    scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.componentId}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    },
    httpAjaxError(e) {
      const response = e.response;
      if (response && 403 === response.status) {
        this.alertMsg = this.$t('error.authorize_action');
        
      } else if (response && 422 === response.status) {
        const feedback = response.data[response.data.jobCase][0];
        if(feedback.spot) {
          this.alertMsg = this.$t('error.attention_required');
          this.errors.add({
            field: `task_predecessor.${feedback.spot}`,
            msg: this.$t(`error.${feedback.clue}`, feedback.args)
          })
        } else {
          this.alertMsg = this.$t('error.internal_server');
        }
      } else {
        this.alertMsg = this.$t('error.internal_server');
      }
      this.scrollToTop();
    },
    async populateTaskLinkType() {
      const service = this.isTemplate? templateTaskService : taskService;
      let list = await service.optionLinkType()
      .then(response => {
        return response;
      })
      .catch(e => {
        this.httpAjaxError(e);
        return [];
      });
      this.optionTaskLinkType.splice(0, this.optionTaskLinkType.length, ...list.map(i => { return { key: i.label, label: this.$t(`task_link_type.${i.label}`)} }));
    },
    async populateTaskTree() {
      const projectId = this.projectId;
      const taskId = this.taskId;
      const taskName = this.taskName;
      const dispatchName = `data/${this.isTemplate? 'templateTaskListTree' : 'taskListTree'}`;
      
      let list = await this.$store.dispatch(dispatchName, { params: { start: 0, limit: -1 }, projectId })
      .then(response => {
        return response? response.data:[];
      })
      .catch(e => {
        this.httpAjaxError(e);
        return [];
      });
      if(list.length < 1) {
        return;
      }
      // list[0].pUuId = null;
      const l = list.map(i => { return { id: i.uuId, label: i.name, parentId: i.pUuId }});
      this.optionTaskFlat = cloneDeep(l);
      l.forEach(i => {
        const filtered = l.filter(j => j.parentId == i.id);
        if(filtered.length > 0) {
          i.children = l.filter(j => j.parentId == i.id);
        }
        if(taskId && taskId === i.id) {
          i.isDisabled = true;
          if(taskName) {
            i.label = taskName;
          }
        }
      });
      this.optionTask.splice(0, this.optionTask.length, ...l.filter(i => i.parentId == 'ROOT'));
    }
    
  }
}
</script>

<style lang="scss" scoped>
  .flex-container {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
  }
      
  .space-evenly { 
    justify-content: space-evenly; 
  }
  
  .date-field {
    width: 40%;
    display: inline-block;
  }
  
</style>